<template>
  <div v-if="params.value || params.value == false" class="pill-wrap">
    <span class="text-center text-bold pill" :class="{ 'blue-pill': trueFalse(params.value) }"> {{ normalizeValues(params.value) }} </span>
  </div>
  <!-- <div>{{ normalizeValues(params.value) }}</div> -->
</template>

<script>
export default {
  name: "BooleanPillRenderer",
  methods: {
    trueFalse(val) {
      val
        ? (val = val
            .toString()
            .toLowerCase()
            .trim())
        : "";
      let rtnVals = {
        yes: true,
        true: true,
        y: true,
        n: false,
        no: false,
        false: false
      };
      return rtnVals[val] ? rtnVals[val] : false;
    },
    normalizeValues(val) {
      val
        ? (val = val
            .toString()
            .toLowerCase()
            .trim())
        : "";
      let rtnVals = {
        yes: "Yes",
        true: "Yes",
        y: "Yes",
        n: "No",
        no: "No",
        false: "No"
      };
      return rtnVals[val] ? rtnVals[val] : "";
    }
  }
};
</script>

<style scoped>
.pill-wrap {
  padding: 17px 0;
}
.pill {
  display: flex;
  height: 26px;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 100px;
  background-color: #e6e6e6;
  color: #656e78;
  font-size: 13px;
}

.blue-pill {
  color: #fff;
  background-color: #2f66aa;
}
</style>
