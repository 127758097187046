<template>
  <div v-if="params.value" class="pill-wrap">
    <span class="text-center text-bold pill capitalize" :class="{ 'green-pill': params.value == 'GENERIC' }"> {{ normalizeValues(params.value) }} </span>
  </div>
</template>

<script>
export default {
  name: "MedicationTypePillRenderer",
  methods: {
    normalizeValues(val) {
      return val
        ? (val = val
            .toString()
            .toLowerCase()
            .trim())
        : "";
    }
  }
};
</script>

<style scoped>
.pill-wrap {
  padding: 17px 0;
}
.pill {
  display: flex;
  height: 26px;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 100px;
  background-color: #e6e6e6;
  color: #656e78;
  font-size: 13px;
}

.green-pill {
  background-color: #70c01c;
  color: #fff;
}
.capitalize {
  text-transform: capitalize;
}
</style>
