<template>
  <div class="full-height">
    <div v-if="isLoading" class="center-center fade-in full-height">
      <ion-spinner class="center-center spinner-large" name="lines" color="dark"></ion-spinner>
    </div>
    <div class="pad-top-twenty pad-bottom-twenty" v-else>
      <div>
        <div>
          <ion-row>
            <ion-col class="margin-bottom-ten">
              <h3>Medical History</h3>
            </ion-col>
          </ion-row>
          <ion-card color="white" v-for="(grid, index) in gridData" :key="index" :class="{ 'is-open': activeAccordion == grid.title }" class="prime-accordion prime-internal-scoll-card sc-ion-card-md-h sc-ion-card-md-s md ion-color ion-color-white hydrated">
            <ion-card-header class="cursor-pointer" @click="toggleAccordion(grid.title)">
              <ion-card-title>
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto">
                    <h6>{{ grid.title }}</h6>
                  </ion-col>
                  <ion-col></ion-col>
                  <ion-col size="auto" class="valign pad-right-twenty">
                    <span class="text-center text-bold pill pill-blue pill-fixed-width"> {{ grid.rowData.length }} </span>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-icon v-if="activeAccordion == grid.title" name="md-remove" color="medium"></ion-icon>
                    <ion-icon v-else name="ios-add" color="medium"></ion-icon>
                  </ion-col>
                </ion-row>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content class="pad-ten scroll-shrink prime-rh-accordion-card rh-accordion-open">
              <div v-if="grid.rowData.length > 0">
                <ion-row v-if="grid.rowData[0].data_as_of_date" class="ion-align-items-center top-divider-thin">
                  <ion-col class="text-muted text-bold pad-fifteen">Data as of: {{ grid.rowData[0].data_as_of_date | moment("MM/DD/YYYY") }}</ion-col>
                  <ion-col></ion-col>
                  <ion-col size="auto">
                    <select class="form-control" @change="groupByField($event.target.value, index, grid.mapKey)">
                      <option value="default" selected class="text-grey">Group by...</option>
                      <option v-for="option in grid.columnDefs.filter(option => option.primeIsRowGroupable).sort((a,b) => a.groupBySortOrder - b.groupBySortOrder)" :key="option.id" :value="option.field">{{ option.headerName }}</option>
                    </select>
                  </ion-col>
                </ion-row>
                <AgGridVue class="prime-grid ag-theme-alpine" rowHeight="62" :columnDefs="grid.columnDefs" :rowData="grid.rowData" :sideBar="sideBar" @grid-ready="onGridReady($event, grid.mapKey)" :modules="modules" :defaultColDef="defaultColDef" :columnTypes="columnTypes" :groupDisplayType="groupDisplayType" :defaultExcelExportParams="defaultExportParams" :defaultCsvExportParams="defaultExportParams" suppressPropertyNamesCheck="true" > </AgGridVue>
              </div>
              <div v-else class="text-center text-muted margin-top-twenty">No data available</div>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { send as httpSend } from "@/services/Api";
import { more } from "ionicons/icons";
import { AgGridVue } from "@ag-grid-community/vue";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import BooleanPillRenderer from "@/components/Global/Grid/BooleanPillRenderer";
import MedicationTypePillRenderer from "@/components/Global/Grid/MedicationTypePillRenderer";
import ResultLevelPillRenderer from "@/components/Global/Grid/ResultLevelPillRenderer";
import moment from "moment";

addIcons({
  "md-more": more.md
});

export default {
  name: "MedicalHistory",
  components: {
    AgGridVue,
    BooleanPillRenderer,
    MedicationTypePillRenderer,
    ResultLevelPillRenderer
  },
  data() {
    return {
      gridApis: {},
      isLoading: false,
      activeAccordion: undefined,
      beneId: undefined,
      rowData: null,
      currentlySelectedGroupField: undefined,
      defaultColDef: {
        filter: "agTextColumnFilter",
        minWidth: 150.0,
        sortable: true,
        resizable: true,
        sideBar: true,
        filterParams: { suppressAndOrCondition: true },
        suppressPivots: true,
        flex: 1.0,
        useValueFormatterForExport: true,
        useValueParserForImport: true
      },
      defaultExportParams: {
        processCellCallback: function (params) {
          if (params.column.colDef.type === "dateColumn" && params.value) {
            return moment(params.value).utc().format('MM/DD/YYYY');
          }
          else if (params.column.colDef.type === "currencyColumn") {
            if (params.value) {
              return "$" + params.value.toFixed(2);
            }
            else {
              return "$0.00";
            }
          }
          return params.value;
        }
      },
      columnTypes: {
        currencyColumn: {
          valueFormatter(params) {
            return "$" + params.value.toFixed(2);
          },
          filter: "agNumberColumnFilter"
        },
        dateColumn: {
          valueFormatter(params) {
            if (params.value) {
              return moment(params.value).utc().format('MM/DD/YYYY');
            }
            return '';
          },
          filter: "agDateColumnFilter",
          filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true,
            comparator(filterValue, cellValue) {
              if (cellValue == undefined) return 0;
              if (cellValue == null) return 0;
              const cellDate = new Date(cellValue).setHours(0, 0, 0, 0);
              if (cellDate < filterValue) return -1;
              if (cellDate > filterValue) return 1;
              return 0;
            }
          }
        }
      },
      gridData: [
        {
          id: 2,
          title: "Medication",
          mapKey: "medications",
          rowData: [],
          columnDefs: [
            { headerName: "Last Filled / Prescribed", field: "fill_dt", type: "dateColumn", width: 250, sort: "desc", sortingOrder: ["desc", "asc"] },
            { headerName: "Name", field: "drug_nm", filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Brand / Generic", field: "generic_brand_desc", cellRenderer: "MedicationTypePillRenderer", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Quantity", field: "qty_amt", hide: true, filter: "agNumberColumnFilter", flex: 2 },
            { headerName: "Day Supply", field: "day_supply_amt", hide: true, filter: "agNumberColumnFilter", flex: 2 },
            { headerName: "High Risk", field: "high_risk_ind", cellRenderer: "BooleanPillRenderer", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Alternative Medication", field: "pref_alt_ind", cellRenderer: "BooleanPillRenderer", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Active", field: "active", cellRenderer: "BooleanPillRenderer", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Prescriber", field: "prescriber_full_nm", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Plan Cost", field: "plan_cost_amt", type: "currencyColumn", hide: true, flex: 2 },
            { headerName: "Member Cost", field: "mbr_cost_amt", type: "currencyColumn", hide: true, flex: 2 },
            { headerName: "Within Enrollment", field: "within_enrollment", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Source", field: "source", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 }
          ]
        },
        {
          id: 3,
          title: "Imaging and Procedures",
          mapKey: "imagingAndProcedures",
          rowData: [],
          columnDefs: [
            { headerName: "Date of Service", field: "date_of_service", type: "dateColumn", width: 160, sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "Service Type", field: "med_service_type", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 120 },
            { headerName: "CPT", field: "cpt_code", hide: true, filter: "agSetColumnFilter", width: 110 },
            { headerName: "Description", field: "service_description", filter: "agSetColumnFilter", primeIsRowGroupable: true, flex: 2 },
            { headerName: "Provider", field: "provider_full_nm", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Site", field: "location", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 220 },
            { headerName: "Source", field: "source", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 120 }
          ]
        },
        {
          id: 4,
          title: "Labs and Results",
          mapKey: "labsAndResults",
          rowData: [],
          columnDefs: [
            { headerName: "Date of Service", field: "date_of_service", type: "dateColumn", width: 160, sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "CPT Code", field: "cpt_code", primeIsRowGroupable: true },
            { headerName: "CPT Description", field: "test_nm", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Key Lab Indicator", field: "key_ind", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Ordering Clinician", field: "order_physician_full_nm", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Ordering Source", field: "source", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 160 },
            { headerName: "Result Name", field: "test_nm", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Result", field: "result_text", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Results Interpretation", field: "out_of_range_text", filter: "agSetColumnFilter", width: 160 },
            { headerName: "Reference Range Low", field: "normal_low_val_amt", hide: true, width: 160 },
            { headerName: "Reference Range High", field: "normal_high_val_amt", hide: true, width: 160 }
          ]
        },
        {
          id: 5,
          title: "Reported Conditions",
          mapKey: "reportedConditions",
          rowData: [],
          columnDefs: [
            { headerName: "Type", field: "reported_condition", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Category", field: "condition_category", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Addressed This Year", field: "addressed_this_yr", cellRenderer: "BooleanPillRenderer", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Code Submitted", field: "code_submitted", width: 170 },
            { headerName: "Date of Service", field: "date_of_service", type: "dateColumn", width: 150 },
            { headerName: "Provider", field: "provider_full_name", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Provider Type", field: "provider_type_description", filter: "agSetColumnFilter", primeIsRowGroupable: true }
          ]
        },
        {
          id: 6,
          title: "Screening and Prevention",
          mapKey: "screeningAndPrevention",
          rowData: [],
          columnDefs: [
            { headerName: "Date of Service", field: "date_of_service", type: "dateColumn", sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "Description", field: "procedure_code_description", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Provider", field: "servicing_provider_full_nm", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Source", field: "source", filter: "agSetColumnFilter", primeIsRowGroupable: true }
          ]
        },
        {
          id: 7,
          title: "Diagnosis",
          mapKey: "diagnosis",
          rowData: [],
          columnDefs: [
            { headerName: "Date Last Reported", field: "diag_dt", type: "dateColumn", width: 150, sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "Dx Code", field: "diag_code", filter: "agSetColumnFilter", width: 120 },
            { headerName: "Dx Description", field: "diag_description", filter: "agSetColumnFilter", width: 150 },
            { headerName: "Dx Category", field: "disease_cat", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, comparator: this.sortByDxRank, sortIndex: 1, width: 120 },
            { headerName: "Dx Rank", field: "disease_rank", hide: true, filter: "agSetColumnFilter", suppressColumnsToolPanel: true, comparator: this.convertNumericSort, sortIndex: 0, width: 120 },
            { headerName: "Provider", field: "submit_prov_full_nm", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Provider Type", field: "provider_type_description", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Chronic", field: "chronic_disease_ind", cellRenderer: "BooleanPillRenderer", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 120 },
            { headerName: "RAPS Eligible", field: "raps_elig_ind", cellRenderer: "BooleanPillRenderer", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 120 },
            { headerName: "Pertinent Condition", field: "pertinent_condition_ind", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Source", field: "source", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true }
          ]
        },
        {
          id: 1,
          title: "Hospital Admissions (ED, IP, Obs)",
          mapKey: "hospitalAdmissions",
          rowData: [],
          columnDefs: [
            { headerName: "Type", field: "admission_type", width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 0 },
            { headerName: "Admit Date", field: "admission_date", type: "dateColumn", width: 170, sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "Discharge Date", field: "discharge_date", type: "dateColumn", width: 180 },
            { headerName: "Facility", field: "service_facility", width: 190, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 1 },
            { headerName: "Admitting Physician", field: "admission_prov_name", width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 2 },
            { headerName: "Admit Dx Code", field: "admission_diag_code", hide: true, width: 120, filter: "agSetColumnFilter" },
            { headerName: "Admit Dx Description", field: "admission_diag_code_desc", width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 3 },
            { headerName: "Discharge Dx Code", field: "discharge_diag_code", hide: true, width: 120, filter: "agSetColumnFilter" },
            { headerName: "Discharge Dx Description", field: "discharge_diag_code_desc", width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 4 },
            { headerName: "Principal Claim Dx Code", field: "primary_claim_diag_code", hide: true, width: 120, filter: "agSetColumnFilter" },
            { headerName: "Principal Claim Dx Description", field: "primary_claim_diag_code_desc", hide: true, width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 6 },
            { headerName: "DRG Code", field: "drg_code", hide: true, width: 120, filter: "agSetColumnFilter" },
            { headerName: "DRG Description", field: "drg_code_description", hide: true, width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 7 },
            { headerName: "Discharge Disposition", field: "discharge_code", width: 120, primeIsRowGroupable: true, filter: "agSetColumnFilter", groupBySortOrder: 5 },
            { headerName: "Source", field: "source", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, groupBySortOrder: 8 },
          ]
        },
        {
          id: 8,
          title: "Services",
          mapKey: "services",
          rowData: [],
          columnDefs: [
            { headerName: "Date of Service", field: "date_of_service", type: "dateColumn", width: 150, sort: "desc", sortingOrder: ["desc", "asc"]  },
            { headerName: "Service Type", field: "med_service_type", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 110 },
            { headerName: "Visit Type", field: "visit_type", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Dx Code", field: "primary_diagnosis", width: 110 },
            { headerName: "Reason for Visit", field: "primary_diagnosis_description", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Location", field: "location", filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Member Cost", field: "mbr_pay_amt", type: "currencyColumn", width: 150 },
            { headerName: "Plan Cost", field: "plan_pay_amt", type: "currencyColumn", width: 120 },
            { headerName: "Provider", field: "provider_full_name", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true },
            { headerName: "Accountable Visit", field: "accountable_visit_ind", cellRenderer: "BooleanPillRenderer", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 150 },
            { headerName: "Specialist Visit", field: "specialist_flag", cellRenderer: "BooleanPillRenderer", hide: true, filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 150 },
            { headerName: "Source", field: "source", filter: "agSetColumnFilter", primeIsRowGroupable: true, width: 150 }
          ]
        }
      ],
      modules: [ClientSideRowModelModule, ClipboardModule, ColumnsToolPanelModule, ExcelExportModule, FiltersToolPanelModule, MenuModule, RowGroupingModule, SetFilterModule],
      sideBar: null,
      groupDisplayType: "groupRows"
    };
  },
  async mounted() {
    this.beneId = this.$store.state.chatProfessional.currentPatient.beneficiary.id;
    this.getGridData();
  },
  methods: {
    sortByDxRank(valueA, valueB, nodeA, nodeB) {
      if (!nodeA || !nodeA.data || !nodeA.data.disease_rank) return 0;
      if (!nodeB || !nodeB.data || !nodeB.data.disease_rank) return 0;
      const nodeAValue = nodeA.data.disease_rank;
      const nodeBValue = nodeB.data.disease_rank;
      if (nodeAValue < nodeBValue) return -1;
      if (nodeAValue > nodeBValue) return 1;
      return 0;
    },
    convertNumericSort(valueA, valueB) {
      if (valueA == undefined || valueB == undefined) return 0;
      if (valueA == null || valueB == null) return 0;
      return parseInt(valueA) - parseInt(valueB);
    },
    toggleAccordion(which) {
      if (this.activeAccordion == which) {
        this.activeAccordion = undefined;
      } else {
        this.activeAccordion = which;
      }
    },
    groupByField(value, index, mapKey) {
      if (value == "default") this.gridApis[mapKey].setRowGroupColumns([]);
      else this.gridApis[mapKey].setRowGroupColumns([value]);
    },
    onGridReady(params, mapKey) {
      this.gridApis[mapKey] = params.columnApi;
      this.sideBar = {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivotMode: true,
              suppressPivots: true,
              suppressRowGroups: true,
              suppressValues: true
            }
          },
          "filters"
        ]
      };
    },
    getGridData() {
      this.isLoading = true;
      const method = "get";
      const path = document.config.medicalHistory + "/" + this.beneId;
      httpSend({ path, method, authToken: true })
        .then(response => {
          response = response.data;

          Object.keys(response).forEach(key => {
            let value = this.gridData.find(function(v) {
              return v.mapKey === key;
            });

            response[key] ? (value.rowData = response[key].rowData) : "";
          });
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error loading patient Medical History info",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      row-height: 62px,
      font-family: inherit,
      header-background-color: null,
      border-color: rgb(242, 243, 245),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      header-column-resize-handle: true,
      header-column-resize-handle-width: 4px,
      header-column-resize-handle-color: ag-derived(border-color)
    )
  );

  .ag-root-wrapper {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .ag-root-wrapper,
  .ag-root-wrapper-body {
    height: 100%;
  }

  span.ag-sort-indicator-icon.ag-sort-order {
    display: none;
  }
}

.prime-grid {
  width: 100%;
  height: 100%;
}

/* Accordion ---------------- */
ion-card.prime-accordion ion-card-header {
  padding: 1px 10px 1px 20px;
}

ion-card.prime-accordion {
  flex: 0 1 auto;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0px;
}

ion-card.prime-accordion:first-child,
ion-card.prime-accordion {
  margin-top: 10px !important;
}

ion-card.prime-accordion ion-card-content {
  margin-top: 10px;
  padding-top: 5px;
  overflow-y: auto;
  flex-grow: 1;
  display: none;
}

ion-card.prime-accordion .ion-card-footer {
  margin: 0 -10px -10px -10px;
  padding: 20px;
  display: none;
}

ion-card.prime-accordion .ion-card-footer-empty {
  padding: 5px;
}

.prime-grid {
  height: 570px;
}

ion-card.prime-accordion.is-open {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 300px;
  height: 700px;
}

ion-card.prime-accordion.is-open ion-card-content,
ion-card.prime-accordion.is-open .ion-card-footer {
  display: initial;
}

/* End Accordion */

.pill-fixed-width {
  width: 60px;
  padding: 2px 10px;
}
</style>
